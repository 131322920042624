.growShrink {
  animation: growShrink 1.5s ease-in-out infinite
}

.backAndForth {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
}

.backAndForth * {
  transform: translateX(0%);
}

.spin {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0%, 100% { transform: rotate(-15deg); }
  50% { transform: rotate(15deg); }
}

.rotatingTilt {
  animation: rotatingTilt 25s ease-in-out infinite;
}

@keyframes rotatingTilt {
  0%, 100% { transform: rotate(35deg); }
  50% { transform: rotate(-25deg); }
}

.bargainBackground {
  position: fixed;
  top: 0;
  padding-top: 250px;
  z-index: -1;
  width: 100vw;
  height: 150vh;
  overflow: hidden;
}

.bargainContent {
  margin: auto;
  margin-top: 50px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 308px 308px 308px;
}

h1.bargainTitle.rmName {
  padding-right: 0
}

@media (max-width: 450px) {
  h1.bargainTitle.rmName {
    font-size: 50px;
  }
}

@media (max-width: 965px) {
  .bargainContent {
    grid-template-columns: 308px 308px;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .bargainContent {
    grid-template-columns: 308px;
  }
}

.bargainGrowShrink {
  animation: bargainGrowShrink 10s ease-in-out infinite
}

@keyframes bargainGrowShrink {
  0% {transform: scale(0);}
  40% {transform: scale(0);}
  45% { transform: scale(1);}
  50%, 60%, 70%, 80%, 90% { transform:  rotate(7deg);}
  55%, 65%, 75%, 85% { transform:  rotate(-7deg);}
  95% { transform: scale(1) rotate(0deg);}
  100% { transform: scale(0);}
}
