
.patriotBackground {
  position: fixed;
  top: -50px;
  left: 0;
  z-index: -100;
  height: calc(100vh + 50px);
  width: 100vw;
  overflow: hidden;
}

.patriotContainer {
  max-width: 360px;
  display: flex;
  justify-content: center;
}
.rotate45 {
  display: inline-block;
  transform: rotate(-45deg);
}

.flagStripe {
  height: 60px;
  width: calc(100vw + 100vh);
}

.flagStripe.red {
  background-color: #b22234;
}

.flagStripe.white {
  background-color: #ffffff;
}

.flagStripe.blue {
  background-image: url(./flagstar.svg);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-color: #3C3B6E;
  animation: starMarquee 30s linear infinite;
}

.patriotMarquee {
  display: inline-block;
  color: #b22234;
  border: 10px solid #3C3B6E;
  background-color: #ffffff;
}

@keyframes starMarquee {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}


.borderRed {
  border-color: #b22234;
}
.borderBlue {
  border-color: #3C3B6E;
}

.borderWhite {
  border-color: #FFFFFF;
}

.patriotContent {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 33% 33% 33% ;
}

@media (max-width: 1260px) {
  .patriotContent {
    grid-column-gap: 10px;
  }
}

@media (max-width: 965px) {
  .patriotContent {
    grid-template-columns: 300px 300px;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .patriotContent {
    grid-template-columns: 100%;
  }
}
