.safetyMarquee {
  display: inline-block;
  color: #000;
  border: 10px solid;
}
.safetyMarquee .rmName  {
  background-color: #ffffff;
}

.topRight {
  position: absolute;
  right: 0;
  top: 0;
  margin-left: -10vw;
}


@keyframes safetyBlink {
  0% {
    opacity: 1;
  }

  50% { opacity: 1 }
  60% { opacity: 0; }

  90% { opacity: 0 }
  100% {
    opacity: 1;
  }
}

@keyframes safetyBorder {
  from {
    border: 5px dashed;
  }

  to {
    border: 5px dotted;
  }
}

.safetyBorder {
  border: 5px dashed;
  animation: safetyBorder 1s linear infinite;
  padding: 10px;

}
.safetyH1 {
  font-size: 70px;
  font-weight: 1;
  animation: safetyBlink 1.25s linear infinite;
  animation-delay: -0.15s;
  text-align: center;
}


@media (max-width: 525px) {
  .safetyH1 {
    font-size: 45px;
  }
}

.safetyContent {
  margin: auto;
  margin-top: 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 308px 308px 308px;

}

@media (max-width: 965px) {
  .safetyContent {
    grid-template-columns: 308px 308px;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .safetyContent {
    grid-template-columns: 308px;
  }
}

.safetyContentBlink {
  animation: safetyContentBlink 3s ease-in-out infinite;
}

@keyframes safetyContentBlink {
  0%,100% { opacity: 1 }
  12.5% { opacity: 0 }
  25% { opacity: 1 }
}
