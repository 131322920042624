
.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.itemHeaderTitle {
  width: calc(100% - 90px);
}


.itemWrapper {
  margin: auto;
  padding: 0 20px;
  max-width: 800px;
  margin-bottom: 10px
}

.itemTitle {
  font-size: 70px;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
}


.itemEmText {
  position: relative;
  top: -0.5em;
  font-size: 20px;
  font-weight: 400;
  border: 1px solid;
  padding: 0.3em;
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
}

.nightmode .itemEmText {
  background-color: #ffffff;
  color: #000000;
}

@media (max-width: 750px) {
  .itemTitle {
    text-align: center;
  }
}
@media (max-width: 550px) {
  .itemTitle {
    font-size: 45px;
  }

  .itemEmText {
    font-size: 15px;
  }

}

.contentGrid {
  display: grid;
  grid-template-columns: 340px 1fr;
  column-gap: 30px;
}

@media (max-width: 750px) {
  .contentGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.itemImage {
  border: 15px double;
  padding: 5px;
  width: 300px;
  height: 300px;
  object-fit: contain;
}

@media (max-width: 400px) {
  .itemImage {
    width: 270px;
    height: 270px;
  }
}

.itemLeftCol {
  margin-bottom: 60px;
}

.itemSectionHeader {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.itemSectionSubHeader {
  font-size: 16px;
  margin-bottom: 0.2em;
  font-weight: 500;
}

@media (max-width: 400px) {
  .itemSectionHeader {
    font-size: 16px;
    font-weight: 500;
  }

}

.description {
  margin-bottom: 40px;
}

.descriptionText {
  line-height: 1.4;
}

.review {
  border: 2px solid;
  margin-bottom: 10px;
}

.reviewRating {
  font-size: 18px;
  font-weight: 500;
  padding: 7px;
  padding-bottom: 0;
}

.reviewContent {
  padding: 7px;
  line-height: 1.4;
  font-size: 14px;
}

.reviewData {
  font-size: 12px;
  border-top: 2px solid;
  padding: 3px 7px;
  background-color: #f6f6f6;
}

.nightmode .reviewData {
  background-color: #363636;
}

.buyNowSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyNowLink {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding: 0.5em;
  border: 3px solid;
  border-radius: 3px;
  margin: 10px 5px;
  transition: 1s;
  box-shadow: -2px 2px 3px #666;
}

.soldOutBuyNow {
  cursor: not-allowed;
  pointer-events: none;
  text-decoration: line-through;
}

.buyNowLink:hover {
  transform: rotate(720deg);
}

.customizeLink {
  margin-top: 0.7em;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.reviews {
  border: 1px dotted;
  padding: 10px;
}

.reviewInput {
  width: 96%;
  height: 125px;
  padding: 7px;
  font-size: 15px;
  line-height: 1.2;
}

.reviewSubmitButton {
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
}

.errorText {
  color: red;
}

.socialShareSection {
  margin-top: 15px;
}

.itemSubHeadingContent {
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  display: inline-block;
}


.itemIntegrationSection {
  margin-top: 45px;
}

@media (max-width: 550px) {
  .itemLeftCol {
    margin-bottom: 40px;
  }

  .itemIntegrationSection {
    margin-top: 25px;
    padding: 0 20px;
  }
}

.soldOutMarquee {
  position: fixed;
  top: 50vh;
  left: -60vw;
  transform: rotate(-45deg);
  z-index: 5;
  border: 2px solid;
  background: #fff;
  opacity: 0.75;
  cursor: not-allowed;
}

.nightmode .soldOutMarquee {
  background: #000;

}

.soldOutText {
  white-space: nowrap;
  padding: 5px;
  padding-right: 50px;
  font-size: 40px;
}
