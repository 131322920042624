@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  font-family: 'Helvetica Neue', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

::selection {
  background-color: #000;
  color: #fff;
}

.nightmode {
  background-color: #000;
  color: #fff;
}

.nightmode ::selection {
  background-color: #fff;
  color: #000;
}

a {
  text-decoration: none;
  color: inherit;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.nightmode .link {
  color: #9999ff
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
}

.headerTitlePadding {
  margin-left: 20px;
}

@media (max-width: 750px) {
  .headerTitlePadding {
    margin-left: 0;
    margin-top: 10px;
  }
}

.headerTitle {
  width: calc(100vw - 500px);
  /*width: 100%;*/
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-width: 750px) {
  .header {
    flex-direction: column;
  }

  .headerTitle {
    width: calc(100% - 40px);
    height: 200px;
  }
}

.background {
  position: absolute;
  z-index: -100;
  height: 100vh;
  width: 100vw;
}

.siteImage {
  width: 300px;
  height: 300px;
}

.imageSpin {
  animation: imageSpin infinite 10s linear;
}

.animationReverse {
  animation-direction: reverse;
}

.logo {
  display: inline-block;
  max-width: 425px;
  max-height: 425px;
  /*width: 100%;*/
  pointer-events: none;
}

@media (max-width: 750px) {
  .logoFull {
    max-width: calc(100% - 40px);
    min-width: 300px;

  }
  .logo {
    /*max-height: 250px;*/
    /*max-width: 250px;*/
    /*margin: auto;*/
  }
}


@keyframes imageSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.verticalSection {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 100%;
  max-width: 1450px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 720px) {
  .verticalSection {
    margin-top: 40px;
  }
}

.brandSection, .bargainSection {
  width: calc(100% - 350px);
  margin-bottom: 20px;
}

@media (max-width: 720px) {
  .bargainSection {
    margin-top: 50px;
  }
}

.fillerSection {
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .verticalSection {
    flex-direction: column;
  }
  .brandSection, .bargainSection {
    width: 100%;
  }
}


.brandSection:hover * {
  opacity: 0.95;
}

.patriotTitle {
  width: 100%;
  background-image: url(/static/media/flagstar.398581c6.svg);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-color: #3C3B6E;
  animation: starMarquee 20s linear infinite;
  display: flex;
  justify-content: center;
}

.patriotTitleMarquee {
  width: 175px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  background-color: #b22234;
  border: 2px solid #ffffff;
  border-bottom: 0;
  border-top: 0;
  box-sizing: border-box;
  text-shadow: 3px 3px 0 #3C3B6E;
  padding: 0.3em 0;
}

.patriotTitleMarqueeText {
  padding-right: 20px;
  font-size: 25px;
  white-space: nowrap;
  width: 150px;
}



.brandedTitleMarquee {
  background-color: #000;
  color: #00ff00;
  text-shadow: 2px 2px #ff00ff;
  border: 4px solid #ff00ff;
  border-bottom: 0;
  margin-bottom: -2px;
  padding: 0.3em 0;
}

.brandedTitleMarqueeText {
  white-space: nowrap;
  font-size: 20px;
  margin-right: 20px;
  font-style: italic;
}


.safetyGridSectionDesktop {
  margin: auto;
  height: 510px;
  width: 510px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.safetyGridSectionMobile {
  display: none;
  height: 300px;
  width: 300px;
  margin: auto;
}

.brandSectionSafety {
  width: 520px;
}

.brandSectionPatriot {
  width: 310px;
}

@media (max-width: 525px) {
  .brandSectionSafety {
    width: 100%
  }

  .safetyGridSectionDesktop {
    width: 100%;
    height: 100%;
    display: none;
  }

  .safetyGridSectionMobile {
    display: block;
  }

}

.safetySectionBorder {
  animation: safetyBorder 1s linear infinite;
}
.safetyTitleMarquee {
  padding: 0.3em 0;
}

.safetyTitleMarqueeText {
  white-space: nowrap;
  margin-right: 20px;
  font-size: 20px;
  animation: safetyBlink 1.25s linear infinite;
}

.bargainTitleMarqueeText {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: growShrink 2s ease-in-out infinite;
  font-size: 35px;
  text-align: center;
}

.imageMarquee {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.imageMarqueeInner {
  animation: marqueeImages 5s linear infinite;
  width: 100%;
  height: 300px;

}

.imageMarqueeInnerInner {
  display: inline-flex;
  animation: marqueeImagesInner 5s linear infinite;
}

.imageMarqueeInnerJerk {
  animation: marqueeImagesJerk 5s ease-in-out infinite;
  width: 100%;
  height: 300px;

}

.imageMarqueeInnerInnerJerk {
  display: inline-flex;
  animation: marqueeImagesInnerJerk 5s ease-in-out infinite;
}

.imageMarqueeChild {
  display: inline-block;
  /*margin-right: 25px;*/
}

.productImage {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.productImageSm {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.imageMarqueeChild > * {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.imageMarqueeChild:first-child {
  border-left: 0;
}

.imageMarqueeChild:last-child {
  border-right: 0;
  margin-right: 0;

}

@keyframes marqueeImages {
  0% { transform: translateX(0%); }
  50% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

@keyframes marqueeImagesInner {
  0% { transform: translateX(0%); }
  50% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}

@keyframes marqueeImagesJerk {
  0% { transform: translateX(0%); }
  4% { transform: translateX(33%); }
  16% { transform: translateX(33%); }
  20% { transform: translateX(66%); }
  33% { transform: translateX(66%); }
  37% { transform: translateX(100%); }
  50% { transform: translateX(100%); }
  54% { transform: translateX(66%); }
  66% { transform: translateX(66%); }
  70% { transform: translateX(33%); }
  83% { transform: translateX(33%); }
  87% { transform: translateX(0%); }
  100% { transform: translateX(0%); }
}

@keyframes marqueeImagesInnerJerk {
  0% { transform: translateX(0%); }
  4% { transform: translateX(-33%); }
  16% { transform: translateX(-33%); }
  20% { transform: translateX(-66%); }
  33% { transform: translateX(-66%); }
  37% { transform: translateX(-100%); }
  50% { transform: translateX(-100%); }
  54% { transform: translateX(-66%); }
  66% { transform: translateX(-66%); }
  70% { transform: translateX(-33%); }
  83% { transform: translateX(-33%); }
  87% { transform: translateX(0%); }
  100% { transform: translateX(0%); }
}


.marqueeBorders {
  border-left: 5px solid;
  border-right: 5px solid;
  margin-bottom: 5px;
}
.marquee {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

.marqueeInner, .marqueeInnerReverse {
  display: inline-flex;
  transform: translate3d(-50%, 0, 0);
}

.marqueeInnerReverse {
  animation: marqueeReverse 5s linear infinite;
}
.marqueeInner {
  animation: marquee 5s linear infinite;
}


.rmName {
  white-space: nowrap;
  padding: 5px;
  padding-right: 40px;
  font-size: 40px;
  font-weight: 400;
}


h1.rmName {
  font-size: 90px
}
h2.rmName {
  font-size: 45px;
}
h3.rmName {
  font-size: 30px;
}
h4.rmName {
  font-size: 14px;
}

@media (max-width: 1150px) {
  h1.rmName {
    font-size: 70px
  }
  h2.rmName {
    font-size: 30px;
  }
  h3.rmName {
    font-size: 20px;
  }
}


@media (max-width: 750px) {
  .headerTitle {
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  h3.rmName {
    font-size: 14px;
  }
}

.buyNow {
  font-size: 60px;
  text-align: center;
  display: inline-block;
  animation: growShrink 1.5s ease-in-out infinite
}

.sale {
  font-size: 50px;
  text-align: center;
  display: inline-block;
  animation: fade 1s ease-in-out infinite;
}
@media (max-width: 750px) {
  .sale {
    font-size: 40px;
  }
}


@keyframes marquee {
  0% {
      transform: translate3d(-10%, 0, 0);
  }

  100% {
      transform: translate3d(0%, 0, 0);
  }
}

@keyframes marqueeReverse {
  0% {
      transform: translate3d(0%, 0, 0);
  }

  100% {
      transform: translate3d(-10%, 0, 0);
  }
}


.blink {
  display: inline-block;
  animation: fade 2s ease-in-out infinite;
}

.blinkFast {
  display: inline-block;
  animation: fade 1s ease-in-out infinite;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

.growShrink {
  text-align: center;
  display: inline-block;
  animation: growShrink 2s ease-in-out infinite
}

.growShrinkShake {
  display: inline-block;
  animation: growShrinkShake 2s ease-in-out infinite
}
.growShrinkShakeDelayed {
  display: inline-block;
  animation: growShrinkShake 2.7s ease-in-out infinite
}

@keyframes growShrink {
  0% {transform: scale(1);}
  50% { transform: scale(0.25);}
  100% { transform: scale(1);}
}

@keyframes growShrinkShake {
  0% {transform: scale(0.1);}
  30% { transform: scale(1);}
  40% { transform:  rotate(30deg);}
  50% { transform:  rotate(-30deg);}
  60% { transform:  rotate(30deg);}
  70% { transform: scale(1) rotate(0deg);}
  100% { transform: scale(0.1);}
}

.spaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content {
  margin: 20px;
}


.imageSection {
  overflow: hidden;
  border: 5px solid;
  /*box-sizing: border-box;*/
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
  color: #000;
}

.imageSection * {
  text-shadow: 3px 3px 0 #FFF;
}

@media (max-width: 750px) {
  .content {
    flex-direction: column;
  }
  .imageSection {
    width: 100%;
  }
}

.tilt90 {
  transform: rotate(-90deg);
}

.tilt270 {
  transform: rotate(-270deg);
}

.tilt {
  height: 0;
  width: 300px;
  position: relative;
  right: 63px;
  top: 87px;
  transform: rotate(-45deg);
  opacity: 0.75;
}

.tiltText {
  padding: 10px;
  background-color: #fff;
  line-height: 1.5;
  color: #000;
}

.nightmode .tiltText {
  background-color: #000;
  color: #fff;
}

.imageSectionTitle {
  padding-right: 20px;
  font-size: 50px;
}

.imageSectionTitleLarge {
  font-size: 80px;
}

@media (max-width: 600px) {
  .imageSectionTitleLarge {
    font-size: 50px
  }
}

@media (max-width: 400px) {
  .imageSectionTitleLarge {
    font-size: 40px
  }
}

.rotateZContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rotateZContent {
  border: 2px solid;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}
.rotateZ {
  animation: rotateZ 1.5s ease-in-out infinite
}

.rotateZFull {
  animation: rotateZFull 3s linear infinite;
  perspective: 600px;

}

@keyframes rotateZ {
  0% {
    transform: perspective(500px) rotate3d(0,2,0, -45deg) translateZ(50px);
  }
  50% {
    transform: perspective(500px) rotate3d(0,2,0, 45deg) translateZ(50px);
    perspective: 200px;
  }
  100% {
    transform: perspective(500px) rotate3d(0,2,0, -45deg) translateZ(50px);
  }
}

@keyframes rotateZFull {
  0% {
    transform: perspective(500px) rotate3d(0,2,0, -0deg) translateZ(75px);
  }
  50% {
    /*transform: perspective(500px) rotate3d(0,2,0, 45deg) translateZ(50px);*/
  }
  100% {
    transform: perspective(500px) rotate3d(0,2,0, -360deg) translateZ(75px);
  }
}


.arrowContainer {
  flex-grow: 1;
  font-size: 25px;
  display: flex;
  align-items: center;
}

.arrowsContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowsInner {
  display: inline-block;
  width: 100%;
  animation: arrowsInner 2s ease-in-out infinite;
}
.arrowsInnerInner {
  display: inline-block;
  animation: arrowsInnerInner 2s ease-in-out infinite;
}

@keyframes arrowsInner {
  0% { transform: translateX(0%); }
  50% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

@keyframes arrowsInnerInner {
  0% { transform: translateX(0%); }
  50% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}



.imageRotateContainer {
  overflow: hidden;
}
.imageRotateContainer > .activeImage {
  transition: 300ms;
}

@keyframes imageRotate {
  0% { margin-left: 0 }
  70% { margin-left: 0}
  95% { margin-left: -400px }
  100% { margin-left: -400px }
}

.itemFeature {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}

.itemFeature:hover * {
  opacity: 0.90;
}


.itemFeature img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}
.itemFeatureSm img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.itemFeatureTitle {
  padding: 5px 0;
  text-align: center;
}


.verticalMarquee {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  border: 5px solid;
  width: 300px;
  overflow: hidden;
  height: 800px;
}

.verticalMarqueeInner {
  animation: verticalMarquee 3s linear infinite
}

@keyframes verticalMarquee {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0%);
  }
}

.verticalMarqueeInnerReverse {
  animation: verticalMarqueeReverse 3s linear infinite
}

@keyframes verticalMarqueeReverse {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-50%);
  }
}

.entireCatalog {
  margin-top: 10px;

  white-space: nowrap;
  text-decoration: underline;
}

.topReviewTestemonial {
  display: inline-block;
  width: 250px;
  transform: translateY(-50%);
  font-size: 30px;
}

.imageShrinkRotation {
  transition: 300ms;
}

@keyframes imageShrinkRotation {
  0% { transform: scale(0); }
  5% { transform: scale(0); }
  17% { transform: scale(1); }
  80% { transform: scale(1); }
  92% { transform: scale(0); }
  100% { transform: scale(0); }
}

.bargainImageSection {
  display: flex;
  justify-content: space-around;
}



.patriotBackground {
  position: fixed;
  top: -50px;
  left: 0;
  z-index: -100;
  height: calc(100vh + 50px);
  width: 100vw;
  overflow: hidden;
}

.patriotContainer {
  max-width: 360px;
  display: flex;
  justify-content: center;
}
.rotate45 {
  display: inline-block;
  transform: rotate(-45deg);
}

.flagStripe {
  height: 60px;
  width: calc(100vw + 100vh);
}

.flagStripe.red {
  background-color: #b22234;
}

.flagStripe.white {
  background-color: #ffffff;
}

.flagStripe.blue {
  background-image: url(/static/media/flagstar.398581c6.svg);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-color: #3C3B6E;
  animation: starMarquee 30s linear infinite;
}

.patriotMarquee {
  display: inline-block;
  color: #b22234;
  border: 10px solid #3C3B6E;
  background-color: #ffffff;
}

@keyframes starMarquee {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}


.borderRed {
  border-color: #b22234;
}
.borderBlue {
  border-color: #3C3B6E;
}

.borderWhite {
  border-color: #FFFFFF;
}

.patriotContent {
  margin-top: 20px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 33% 33% 33% ;
}

@media (max-width: 1260px) {
  .patriotContent {
    grid-column-gap: 10px;
  }
}

@media (max-width: 965px) {
  .patriotContent {
    grid-template-columns: 300px 300px;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .patriotContent {
    grid-template-columns: 100%;
  }
}

.safetyMarquee {
  display: inline-block;
  color: #000;
  border: 10px solid;
}
.safetyMarquee .rmName  {
  background-color: #ffffff;
}

.topRight {
  position: absolute;
  right: 0;
  top: 0;
  margin-left: -10vw;
}


@keyframes safetyBlink {
  0% {
    opacity: 1;
  }

  50% { opacity: 1 }
  60% { opacity: 0; }

  90% { opacity: 0 }
  100% {
    opacity: 1;
  }
}

@keyframes safetyBorder {
  from {
    border: 5px dashed;
  }

  to {
    border: 5px dotted;
  }
}

.safetyBorder {
  border: 5px dashed;
  animation: safetyBorder 1s linear infinite;
  padding: 10px;

}
.safetyH1 {
  font-size: 70px;
  font-weight: 1;
  animation: safetyBlink 1.25s linear infinite;
  animation-delay: -0.15s;
  text-align: center;
}


@media (max-width: 525px) {
  .safetyH1 {
    font-size: 45px;
  }
}

.safetyContent {
  margin: auto;
  margin-top: 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 308px 308px 308px;

}

@media (max-width: 965px) {
  .safetyContent {
    grid-template-columns: 308px 308px;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .safetyContent {
    grid-template-columns: 308px;
  }
}

.safetyContentBlink {
  animation: safetyContentBlink 3s ease-in-out infinite;
}

@keyframes safetyContentBlink {
  0%,100% { opacity: 1 }
  12.5% { opacity: 0 }
  25% { opacity: 1 }
}

.brandedMarquee {
  border: 6px solid;
}

.brandedBorder {
  border: 4px solid;
}

.brandedTitle {
  font-weight: 200;
  font-style: italic;
}

.brandedBackground {
  min-height: 100vh;
  background-color: #111;
}


.flashing {
  animation: FullColorRotate 18s linear infinite;

}



.flashingSvg .__svgOutter {
  animation: FullColorRotateSVG 18s linear infinite;
}

.flashingSvg .__svgMiddle {
  animation: FullColorRotateSVG 18s linear infinite;
}

.flashingSvg .__svgInner {
  animation: FullColorRotateSVG 18s linear infinite;
}

.flashingSvg .__svgRM {
  animation: FullColorRotateSVGLogo 18s linear infinite;
}


.flashingSvgInverse .__svgOutter {
  animation: FullColorRotateSVGInverse 18s linear infinite;
}

.flashingSvgInverse .__svgMiddle {
  animation: FullColorRotateSVGInverse 18s linear infinite;
}

.flashingSvgInverse .__svgInner {
  animation: FullColorRotateSVGInverse 18s linear infinite;
}

.flashingSvgInverse .__svgRM {
  animation: FullColorRotateSVGLogoInverse 18s linear infinite;
}


@keyframes FullColorRotate {
  0% {
    color: #ff0000;
    stroke: #ff0000;
    fill: #ff0000;
    /*background-color: #00ffff;*/
    text-shadow: 3px 3px 0 #00ffff;
    box-shadow: 3px 3px 0 #00ffff;
  }

  17% {
    color: #ffff00;
    stroke: #ffff00;
    fill: #ffff00;
    /*background-color: #0000ff;*/
    text-shadow: 3px 3px 0 #0000ff;
    box-shadow: 3px 3px 0 #0000ff;
  }

  33% {
    color: #00ff00;
    stroke: #00ff00;
    fill: #00ff00;
    /*background-color: #ff00ff;*/
    text-shadow: 3px 3px 0 #ff00ff;
    box-shadow: 3px 3px 0 #ff00ff;
  }

  50% {
    color: #00ffff;
    stroke: #00ffff;
    fill: #00ffff;
    /*background-color: #ff0000;*/
    text-shadow: 3px 3px 0 #ff0000;
    box-shadow: 3px 3px 0 #ff0000;
  }

  66% {
    color: #0000ff;
    stroke: #0000ff;
    fill: #0000ff;
    /*background-color: #ffff00;*/
    text-shadow: 3px 3px 0 #ffff00;
    box-shadow: 3px 3px 0 #ffff00;
  }

  83% {
    color: #ff00ff;
    stroke: #ff00ff;
    fill: #ff00ff;
    /*background-color: #00ff00;*/
    text-shadow: 3px 3px 0 #00ff00;
    box-shadow: 3px 3px 0 #00ff00;
  }

  100% {
    color: #ff0000;
    stroke: #ff0000;
    fill: #ff0000;
    /*background-color: #00ffff;*/
    text-shadow: 3px 3px 0 #00ffff;
    box-shadow: 3px 3px 0 #00ffff;
  }
}

@keyframes FullColorRotateSVG {
  0% {
    stroke: #ff0000;
    /*fill: #00ffff;*/
  }

  17% {
    stroke: #ffff00;
    /*fill: #0000ff;*/
  }

  33% {
    stroke: #00ff00;
    /*fill: #ff00ff;*/
  }

  50% {
    stroke: #00ffff;
    /*fill: #ff0000;*/
  }

  66% {
    stroke: #0000ff;
    /*fill: #ffff00;*/
  }

  83% {
    stroke: #ff00ff;
    /*fill: #00ff00;*/
  }

  100% {
    stroke: #ff0000;
    /*fill: #00ffff;*/
  }
}


@keyframes FullColorRotateSVGLogo {
  0% {
    fill: #ff0000;
  }

  17% {
    fill: #ffff00;
  }

  33% {
    fill: #00ff00;
  }

  50% {
    fill: #00ffff;
  }

  66% {
    fill: #0000ff;
  }

  83% {
    fill: #ff00ff;
  }

  100% {
    fill: #ff0000;
  }
}


@keyframes FullColorRotateSVGInverse {
  0% {
    stroke: #00ffff;
  }

  17% {
    stroke: #0000ff;
  }

  33% {
    stroke: #ff00ff;
  }

  50% {
    stroke: #ff0000;
  }

  66% {
    stroke: #ffff00;
  }

  83% {
    stroke: #00ff00;
  }

  100% {
    stroke: #00ffff;
  }
}


@keyframes FullColorRotateSVGLogoInverse {
  0% {
    fill: #00ffff;
  }

  17% {
    fill: #0000ff;
  }

  33% {
    fill: #ff00ff;
  }

  50% {
    fill: #ff0000;
  }

  66% {
    fill: #ffff00;
  }

  83% {
    fill: #00ff00;
  }

  100% {
    fill: #00ffff;
  }
}


.brandedContent {
  padding: 0 20px;
  margin-top: 20px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 965px) {
  .brandedContent {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .brandedContent {
    grid-template-columns: 1fr;
  }
}

.brandedItemFeature {
  /*animation: hueRotate 3s linear infinite;*/
}

.brandedItemFeature:hover {
  filter: invert(1) ;
  animation: none;
}


@keyframes hueRotate {
  0% { filter: hue-rotate(0deg); }
  16% { filter: hue-rotate(60deg); }
  33% { filter: hue-rotate(120deg); }
  50% { filter: hue-rotate(180deg); }
  66% { filter: hue-rotate(240deg); }
  82% { filter: hue-rotate(300deg); }
  100% { filter: hue-rotate(360deg); }
}

.growShrink {
  animation: growShrink 1.5s ease-in-out infinite
}

.backAndForth {
  display: inline-block;
  max-width: 100%;
  box-sizing: border-box;
}

.backAndForth * {
  transform: translateX(0%);
}

.spin {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  0%, 100% { transform: rotate(-15deg); }
  50% { transform: rotate(15deg); }
}

.rotatingTilt {
  animation: rotatingTilt 25s ease-in-out infinite;
}

@keyframes rotatingTilt {
  0%, 100% { transform: rotate(35deg); }
  50% { transform: rotate(-25deg); }
}

.bargainBackground {
  position: fixed;
  top: 0;
  padding-top: 250px;
  z-index: -1;
  width: 100vw;
  height: 150vh;
  overflow: hidden;
}

.bargainContent {
  margin: auto;
  margin-top: 50px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 308px 308px 308px;
}

h1.bargainTitle.rmName {
  padding-right: 0
}

@media (max-width: 450px) {
  h1.bargainTitle.rmName {
    font-size: 50px;
  }
}

@media (max-width: 965px) {
  .bargainContent {
    grid-template-columns: 308px 308px;
    grid-column-gap: 60px;
  }
}

@media (max-width: 700px) {
  .bargainContent {
    grid-template-columns: 308px;
  }
}

.bargainGrowShrink {
  animation: bargainGrowShrink 10s ease-in-out infinite
}

@keyframes bargainGrowShrink {
  0% {transform: scale(0);}
  40% {transform: scale(0);}
  45% { transform: scale(1);}
  50%, 60%, 70%, 80%, 90% { transform:  rotate(7deg);}
  55%, 65%, 75%, 85% { transform:  rotate(-7deg);}
  95% { transform: scale(1) rotate(0deg);}
  100% { transform: scale(0);}
}


.itemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.itemHeaderTitle {
  width: calc(100% - 90px);
}


.itemWrapper {
  margin: auto;
  padding: 0 20px;
  max-width: 800px;
  margin-bottom: 10px
}

.itemTitle {
  font-size: 70px;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.75em;
}


.itemEmText {
  position: relative;
  top: -0.5em;
  font-size: 20px;
  font-weight: 400;
  border: 1px solid;
  padding: 0.3em;
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
}

.nightmode .itemEmText {
  background-color: #ffffff;
  color: #000000;
}

@media (max-width: 750px) {
  .itemTitle {
    text-align: center;
  }
}
@media (max-width: 550px) {
  .itemTitle {
    font-size: 45px;
  }

  .itemEmText {
    font-size: 15px;
  }

}

.contentGrid {
  display: grid;
  grid-template-columns: 340px 1fr;
  grid-column-gap: 30px;
  column-gap: 30px;
}

@media (max-width: 750px) {
  .contentGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.itemImage {
  border: 15px double;
  padding: 5px;
  width: 300px;
  height: 300px;
  object-fit: contain;
}

@media (max-width: 400px) {
  .itemImage {
    width: 270px;
    height: 270px;
  }
}

.itemLeftCol {
  margin-bottom: 60px;
}

.itemSectionHeader {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.itemSectionSubHeader {
  font-size: 16px;
  margin-bottom: 0.2em;
  font-weight: 500;
}

@media (max-width: 400px) {
  .itemSectionHeader {
    font-size: 16px;
    font-weight: 500;
  }

}

.description {
  margin-bottom: 40px;
}

.descriptionText {
  line-height: 1.4;
}

.review {
  border: 2px solid;
  margin-bottom: 10px;
}

.reviewRating {
  font-size: 18px;
  font-weight: 500;
  padding: 7px;
  padding-bottom: 0;
}

.reviewContent {
  padding: 7px;
  line-height: 1.4;
  font-size: 14px;
}

.reviewData {
  font-size: 12px;
  border-top: 2px solid;
  padding: 3px 7px;
  background-color: #f6f6f6;
}

.nightmode .reviewData {
  background-color: #363636;
}

.buyNowSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyNowLink {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding: 0.5em;
  border: 3px solid;
  border-radius: 3px;
  margin: 10px 5px;
  transition: 1s;
  box-shadow: -2px 2px 3px #666;
}

.soldOutBuyNow {
  cursor: not-allowed;
  pointer-events: none;
  text-decoration: line-through;
}

.buyNowLink:hover {
  transform: rotate(720deg);
}

.customizeLink {
  margin-top: 0.7em;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.reviews {
  border: 1px dotted;
  padding: 10px;
}

.reviewInput {
  width: 96%;
  height: 125px;
  padding: 7px;
  font-size: 15px;
  line-height: 1.2;
}

.reviewSubmitButton {
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
}

.errorText {
  color: red;
}

.socialShareSection {
  margin-top: 15px;
}

.itemSubHeadingContent {
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  display: inline-block;
}


.itemIntegrationSection {
  margin-top: 45px;
}

@media (max-width: 550px) {
  .itemLeftCol {
    margin-bottom: 40px;
  }

  .itemIntegrationSection {
    margin-top: 25px;
    padding: 0 20px;
  }
}

.soldOutMarquee {
  position: fixed;
  top: 50vh;
  left: -60vw;
  transform: rotate(-45deg);
  z-index: 5;
  border: 2px solid;
  background: #fff;
  opacity: 0.75;
  cursor: not-allowed;
}

.nightmode .soldOutMarquee {
  background: #000;

}

.soldOutText {
  white-space: nowrap;
  padding: 5px;
  padding-right: 50px;
  font-size: 40px;
}

.allItemsWrapper {
  max-width: 1000px;
  padding: 0 20px;
  margin: auto;
}
.allItemsContent {
  margin: auto;
  margin-top: 20px;
  display: grid;
  justify-items: center;
  grid-gap: 15px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

}

@media (max-width: 965px) {
  .allItemsContent {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 700px) {
  .allItemsContent {
    grid-template-columns: 1fr;
  }
}


@media (max-width: 350px) {
  .allItemsWrapper {
    padding: 0 5px;
  }
}

.allItemsTitle {
  font-size: 50px;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}

.footer {
  margin-top: 100px;
  padding: 5px 20px;
  font-size: 14px;
}

.footerSection {
  margin-bottom: 5px;
}

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 300ms;
  pointer-events: none;
}

.modal.displayModal {
  opacity: 1;
  pointer-events: auto;
}
.modalBackdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  opacity: 0.8;
  cursor: pointer;
}


.modalContent {
  min-width: 300px;
  max-width: 1000px;
  background-color: #fff;
  z-index: 501;
  border: 2px solid;
  padding: 20px;
  padding-top: 0;
}

.nightmode .modalContent {
  background-color: #000;
}


.modalInput {
  padding: 0.3em;
  font-size: 25px;
  border: 2px solid;
  border-radius: 2px;
  display: block;
  margin: auto;
  width: 280px;
  transition: 300ms;
}

.signupButton {
  cursor: pointer;
  transition: 200ms;
  font-size: 16px;
  padding: 0.4em;
  border: 2px solid;

}
.signupButton:hover {
  background-color: #000;
  border: 2px solid #000;
  color: #fff;
}

.modalInput:focus, .modalInput:active {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.modalTitle {
  font-weight: 400;
  text-align: center;
  padding: 0.3em 1em;
  line-height: 1.3;
}

h2.modalTitle {
  font-size: 40px;
}

h3.modalTitle {
  font-size: 20px;
}


@media (max-width: 500px) {
  .countdownLogo {
    max-width: 80vw;
    max-height: 80vw;
  }
}

