.allItemsWrapper {
  max-width: 1000px;
  padding: 0 20px;
  margin: auto;
}
.allItemsContent {
  margin: auto;
  margin-top: 20px;
  display: grid;
  justify-items: center;
  grid-gap: 15px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

}

@media (max-width: 965px) {
  .allItemsContent {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 700px) {
  .allItemsContent {
    grid-template-columns: 1fr;
  }
}


@media (max-width: 350px) {
  .allItemsWrapper {
    padding: 0 5px;
  }
}

.allItemsTitle {
  font-size: 50px;
  font-weight: 400;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}
