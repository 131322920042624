a {
  text-decoration: none;
  color: inherit;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.nightmode .link {
  color: #9999ff
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
}

.headerTitlePadding {
  margin-left: 20px;
}

@media (max-width: 750px) {
  .headerTitlePadding {
    margin-left: 0;
    margin-top: 10px;
  }
}

.headerTitle {
  width: calc(100vw - 500px);
  /*width: 100%;*/
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-width: 750px) {
  .header {
    flex-direction: column;
  }

  .headerTitle {
    width: calc(100% - 40px);
    height: 200px;
  }
}

.background {
  position: absolute;
  z-index: -100;
  height: 100vh;
  width: 100vw;
}

.siteImage {
  width: 300px;
  height: 300px;
}

.imageSpin {
  animation: imageSpin infinite 10s linear;
}

.animationReverse {
  animation-direction: reverse;
}

.logo {
  display: inline-block;
  max-width: 425px;
  max-height: 425px;
  /*width: 100%;*/
  pointer-events: none;
}

@media (max-width: 750px) {
  .logoFull {
    max-width: calc(100% - 40px);
    min-width: 300px;

  }
  .logo {
    /*max-height: 250px;*/
    /*max-width: 250px;*/
    /*margin: auto;*/
  }
}


@keyframes imageSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.verticalSection {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 50px;
  width: 100%;
  max-width: 1450px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 720px) {
  .verticalSection {
    margin-top: 40px;
  }
}

.brandSection, .bargainSection {
  width: calc(100% - 350px);
  margin-bottom: 20px;
}

@media (max-width: 720px) {
  .bargainSection {
    margin-top: 50px;
  }
}

.fillerSection {
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .verticalSection {
    flex-direction: column;
  }
  .brandSection, .bargainSection {
    width: 100%;
  }
}


.brandSection:hover * {
  opacity: 0.95;
}

.patriotTitle {
  width: 100%;
  background-image: url(./flagstar.svg);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-color: #3C3B6E;
  animation: starMarquee 20s linear infinite;
  display: flex;
  justify-content: center;
}

.patriotTitleMarquee {
  width: 175px;
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  background-color: #b22234;
  border: 2px solid #ffffff;
  border-bottom: 0;
  border-top: 0;
  box-sizing: border-box;
  text-shadow: 3px 3px 0 #3C3B6E;
  padding: 0.3em 0;
}

.patriotTitleMarqueeText {
  padding-right: 20px;
  font-size: 25px;
  white-space: nowrap;
  width: 150px;
}



.brandedTitleMarquee {
  background-color: #000;
  color: #00ff00;
  text-shadow: 2px 2px #ff00ff;
  border: 4px solid #ff00ff;
  border-bottom: 0;
  margin-bottom: -2px;
  padding: 0.3em 0;
}

.brandedTitleMarqueeText {
  white-space: nowrap;
  font-size: 20px;
  margin-right: 20px;
  font-style: italic;
}


.safetyGridSectionDesktop {
  margin: auto;
  height: 510px;
  width: 510px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.safetyGridSectionMobile {
  display: none;
  height: 300px;
  width: 300px;
  margin: auto;
}

.brandSectionSafety {
  width: 520px;
}

.brandSectionPatriot {
  width: 310px;
}

@media (max-width: 525px) {
  .brandSectionSafety {
    width: 100%
  }

  .safetyGridSectionDesktop {
    width: 100%;
    height: 100%;
    display: none;
  }

  .safetyGridSectionMobile {
    display: block;
  }

}

.safetySectionBorder {
  animation: safetyBorder 1s linear infinite;
}
.safetyTitleMarquee {
  padding: 0.3em 0;
}

.safetyTitleMarqueeText {
  white-space: nowrap;
  margin-right: 20px;
  font-size: 20px;
  animation: safetyBlink 1.25s linear infinite;
}

.bargainTitleMarqueeText {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: growShrink 2s ease-in-out infinite;
  font-size: 35px;
  text-align: center;
}

.imageMarquee {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.imageMarqueeInner {
  animation: marqueeImages 5s linear infinite;
  width: 100%;
  height: 300px;

}

.imageMarqueeInnerInner {
  display: inline-flex;
  animation: marqueeImagesInner 5s linear infinite;
}

.imageMarqueeInnerJerk {
  animation: marqueeImagesJerk 5s ease-in-out infinite;
  width: 100%;
  height: 300px;

}

.imageMarqueeInnerInnerJerk {
  display: inline-flex;
  animation: marqueeImagesInnerJerk 5s ease-in-out infinite;
}

.imageMarqueeChild {
  display: inline-block;
  /*margin-right: 25px;*/
}

.productImage {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.productImageSm {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.imageMarqueeChild > * {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.imageMarqueeChild:first-child {
  border-left: 0;
}

.imageMarqueeChild:last-child {
  border-right: 0;
  margin-right: 0;

}

@keyframes marqueeImages {
  0% { transform: translateX(0%); }
  50% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

@keyframes marqueeImagesInner {
  0% { transform: translateX(0%); }
  50% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}

@keyframes marqueeImagesJerk {
  0% { transform: translateX(0%); }
  4% { transform: translateX(33%); }
  16% { transform: translateX(33%); }
  20% { transform: translateX(66%); }
  33% { transform: translateX(66%); }
  37% { transform: translateX(100%); }
  50% { transform: translateX(100%); }
  54% { transform: translateX(66%); }
  66% { transform: translateX(66%); }
  70% { transform: translateX(33%); }
  83% { transform: translateX(33%); }
  87% { transform: translateX(0%); }
  100% { transform: translateX(0%); }
}

@keyframes marqueeImagesInnerJerk {
  0% { transform: translateX(0%); }
  4% { transform: translateX(-33%); }
  16% { transform: translateX(-33%); }
  20% { transform: translateX(-66%); }
  33% { transform: translateX(-66%); }
  37% { transform: translateX(-100%); }
  50% { transform: translateX(-100%); }
  54% { transform: translateX(-66%); }
  66% { transform: translateX(-66%); }
  70% { transform: translateX(-33%); }
  83% { transform: translateX(-33%); }
  87% { transform: translateX(0%); }
  100% { transform: translateX(0%); }
}


.marqueeBorders {
  border-left: 5px solid;
  border-right: 5px solid;
  margin-bottom: 5px;
}
.marquee {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}

.marqueeInner, .marqueeInnerReverse {
  display: inline-flex;
  transform: translate3d(-50%, 0, 0);
}

.marqueeInnerReverse {
  animation: marqueeReverse 5s linear infinite;
}
.marqueeInner {
  animation: marquee 5s linear infinite;
}


.rmName {
  white-space: nowrap;
  padding: 5px;
  padding-right: 40px;
  font-size: 40px;
  font-weight: 400;
}


h1.rmName {
  font-size: 90px
}
h2.rmName {
  font-size: 45px;
}
h3.rmName {
  font-size: 30px;
}
h4.rmName {
  font-size: 14px;
}

@media (max-width: 1150px) {
  h1.rmName {
    font-size: 70px
  }
  h2.rmName {
    font-size: 30px;
  }
  h3.rmName {
    font-size: 20px;
  }
}


@media (max-width: 750px) {
  .headerTitle {
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  h3.rmName {
    font-size: 14px;
  }
}

.buyNow {
  font-size: 60px;
  text-align: center;
  display: inline-block;
  animation: growShrink 1.5s ease-in-out infinite
}

.sale {
  font-size: 50px;
  text-align: center;
  display: inline-block;
  animation: fade 1s ease-in-out infinite;
}
@media (max-width: 750px) {
  .sale {
    font-size: 40px;
  }
}


@keyframes marquee {
  0% {
      transform: translate3d(-10%, 0, 0);
  }

  100% {
      transform: translate3d(0%, 0, 0);
  }
}

@keyframes marqueeReverse {
  0% {
      transform: translate3d(0%, 0, 0);
  }

  100% {
      transform: translate3d(-10%, 0, 0);
  }
}


.blink {
  display: inline-block;
  animation: fade 2s ease-in-out infinite;
}

.blinkFast {
  display: inline-block;
  animation: fade 1s ease-in-out infinite;
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

.growShrink {
  text-align: center;
  display: inline-block;
  animation: growShrink 2s ease-in-out infinite
}

.growShrinkShake {
  display: inline-block;
  animation: growShrinkShake 2s ease-in-out infinite
}
.growShrinkShakeDelayed {
  display: inline-block;
  animation: growShrinkShake 2.7s ease-in-out infinite
}

@keyframes growShrink {
  0% {transform: scale(1);}
  50% { transform: scale(0.25);}
  100% { transform: scale(1);}
}

@keyframes growShrinkShake {
  0% {transform: scale(0.1);}
  30% { transform: scale(1);}
  40% { transform:  rotate(30deg);}
  50% { transform:  rotate(-30deg);}
  60% { transform:  rotate(30deg);}
  70% { transform: scale(1) rotate(0deg);}
  100% { transform: scale(0.1);}
}

.spaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.content {
  margin: 20px;
}


.imageSection {
  overflow: hidden;
  border: 5px solid;
  /*box-sizing: border-box;*/
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
  color: #000;
}

.imageSection * {
  text-shadow: 3px 3px 0 #FFF;
}

@media (max-width: 750px) {
  .content {
    flex-direction: column;
  }
  .imageSection {
    width: 100%;
  }
}

.tilt90 {
  transform: rotate(-90deg);
}

.tilt270 {
  transform: rotate(-270deg);
}

.tilt {
  height: 0;
  width: 300px;
  position: relative;
  right: 63px;
  top: 87px;
  transform: rotate(-45deg);
  opacity: 0.75;
}

.tiltText {
  padding: 10px;
  background-color: #fff;
  line-height: 1.5;
  color: #000;
}

.nightmode .tiltText {
  background-color: #000;
  color: #fff;
}

.imageSectionTitle {
  padding-right: 20px;
  font-size: 50px;
}

.imageSectionTitleLarge {
  font-size: 80px;
}

@media (max-width: 600px) {
  .imageSectionTitleLarge {
    font-size: 50px
  }
}

@media (max-width: 400px) {
  .imageSectionTitleLarge {
    font-size: 40px
  }
}

.rotateZContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rotateZContent {
  border: 2px solid;
  padding: 5px;
  display: inline-block;
  white-space: nowrap;
}
.rotateZ {
  animation: rotateZ 1.5s ease-in-out infinite
}

.rotateZFull {
  animation: rotateZFull 3s linear infinite;
  perspective: 600px;

}

@keyframes rotateZ {
  0% {
    transform: perspective(500px) rotate3d(0,2,0, -45deg) translateZ(50px);
  }
  50% {
    transform: perspective(500px) rotate3d(0,2,0, 45deg) translateZ(50px);
    perspective: 200px;
  }
  100% {
    transform: perspective(500px) rotate3d(0,2,0, -45deg) translateZ(50px);
  }
}

@keyframes rotateZFull {
  0% {
    transform: perspective(500px) rotate3d(0,2,0, -0deg) translateZ(75px);
  }
  50% {
    /*transform: perspective(500px) rotate3d(0,2,0, 45deg) translateZ(50px);*/
  }
  100% {
    transform: perspective(500px) rotate3d(0,2,0, -360deg) translateZ(75px);
  }
}


.arrowContainer {
  flex-grow: 1;
  font-size: 25px;
  display: flex;
  align-items: center;
}

.arrowsContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowsInner {
  display: inline-block;
  width: 100%;
  animation: arrowsInner 2s ease-in-out infinite;
}
.arrowsInnerInner {
  display: inline-block;
  animation: arrowsInnerInner 2s ease-in-out infinite;
}

@keyframes arrowsInner {
  0% { transform: translateX(0%); }
  50% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
}

@keyframes arrowsInnerInner {
  0% { transform: translateX(0%); }
  50% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}



.imageRotateContainer {
  overflow: hidden;
}
.imageRotateContainer > .activeImage {
  transition: 300ms;
}

@keyframes imageRotate {
  0% { margin-left: 0 }
  70% { margin-left: 0}
  95% { margin-left: -400px }
  100% { margin-left: -400px }
}

.itemFeature {
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}

.itemFeature:hover * {
  opacity: 0.90;
}


.itemFeature img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}
.itemFeatureSm img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.itemFeatureTitle {
  padding: 5px 0;
  text-align: center;
}


.verticalMarquee {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  border: 5px solid;
  width: 300px;
  overflow: hidden;
  height: 800px;
}

.verticalMarqueeInner {
  animation: verticalMarquee 3s linear infinite
}

@keyframes verticalMarquee {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0%);
  }
}

.verticalMarqueeInnerReverse {
  animation: verticalMarqueeReverse 3s linear infinite
}

@keyframes verticalMarqueeReverse {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-50%);
  }
}

.entireCatalog {
  margin-top: 10px;

  white-space: nowrap;
  text-decoration: underline;
}

.topReviewTestemonial {
  display: inline-block;
  width: 250px;
  transform: translateY(-50%);
  font-size: 30px;
}

.imageShrinkRotation {
  transition: 300ms;
}

@keyframes imageShrinkRotation {
  0% { transform: scale(0); }
  5% { transform: scale(0); }
  17% { transform: scale(1); }
  80% { transform: scale(1); }
  92% { transform: scale(0); }
  100% { transform: scale(0); }
}

.bargainImageSection {
  display: flex;
  justify-content: space-around;
}

